import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { pageMount } from '../state/page';

const HomePage = ({ onMount = () => {} }) => {
  useEffect(() => {
    onMount();
  }, []);

  return <React.Fragment />;
};

export default connect(null, (dispatch) => ({
  onMount: () => {
    dispatch(pageMount('home'));
  },
}))(HomePage);
